@media only screen and (min-width: $screen-m) {

    .points-page {
        .points__subtitle {
            font-size: 2.3rem;
            margin: 1rem 5rem 5rem;
        }

        .points-container {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            width: 100%;
            align-content: flex-end;
        }

        .disclaimer {
            width: 30%;
            align-self: flex-start;
            margin: 3rem 8rem 3rem 5rem;
        }

        .disclaimer__image {
            width: auto;
        }

        .points {
            margin-top: 1.5rem;
            margin-right: 5rem;
            flex-grow: 1;
            width: 60%;
        }

        .points__description-list {
            margin-bottom: 6rem;
        }

        .extra-chance {
            margin: 0 5rem;
        }

        .extra-chance__title {
            font-size: 7.5rem;
        }

        .extra-chance__subtitle {
            font-size: 2.3rem;
        }

        .extra-chance__button {
            margin-top: 3rem;
            //line-height: 4.8rem;
        }

        .vouchers-info {
            margin: 0 5rem;
        }

        .vouchers__title {
            font-size: 7.5rem;
        }

        .vouchers__subtitle {
            font-size: 2.3rem;
        }

        .voucher-container {
            margin-top: 5rem;

            .voucher {
                padding: 5rem 4rem;
                width: 24%;
            }

            .voucher__logo {
                margin-bottom: 9.5rem;
            }

            .voucher__spacer {
                top: 14rem;
            }
        }

        .vouchers__footer {
            margin: 9.5rem 5rem 8rem 5rem;
        }
    }
}