/*----------------------------------
    #Mountly Quiz - Steps
----------------------------------*/

@media only screen and (min-width: $screen-m) {
    .question-wrapper {
        margin-bottom: 2.5rem;
    }

    .quiz-container {
        .container {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }

    }

    .quiz-form-container {
        width: 50%;
    }
}