@media only screen and (min-width: $screen-m) {
    .login-page {
        width: 100%;

        .container {
            -webkit-box-align: flex-start;
            -moz-box-align: flex-start;
            -ms-flex-align: flex-start;
            -webkit-align-items: flex-start;
            align-items: flex-start;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            justify-content: space-between;

            /* --- Login --- */

            .login {
                margin: 5.5rem 5rem 0 5rem;
                width: 58%;
            }

        }
    }

}