@media only screen and(min-width: $screen-m) {
    .active-quiz-prize-container {
        //width: 45%;
    }

    .active-quiz-prize__sticker {
        right: 5rem;
    }

    .monthly-quiz-container-top {
        .active-quiz-prize__sticker {
            right: 10rem;
        }
    }
}

