@media only screen and (min-width: $screen-m) {
    .signup-page {
        width: 100%;

        .title__container {
            .title {
                padding-left: 2.5rem;
            }
        }

        > .container {
            -webkit-box-align: flex-start;
            -moz-box-align: flex-start;
            -ms-flex-align: flex-start;
            -webkit-align-items: flex-start;
            align-items: flex-start;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            justify-content: space-between;

            /* --- Login --- */

            .signup {
                width: 58%;
                margin: 5.5rem 5rem 0 5rem;

                #fld_register {
                    .form-group {
                        box-sizing: border-box;
                        float: left;
                        width: 50%;

                        &:first-child,
                        &:nth-child(5),
                        &:nth-child(7) {
                            padding-right: 2.5rem;
                        }

                        &:nth-child(2),
                        &:nth-child(6),
                        &:nth-child(8),
                        &:nth-child(10) {
                            padding-left: 2.5rem;
                        }

                        .label-checkbox:before {
                            top: 1.2rem;
                            transform: none;
                        }
                        
                        .more,
                        .more-info {
                            width: 100%;
                        }

                        label[for="gdpr"],
                        label[for="invites"],
                        .more,
                        .more-info {
                            text-align: justify;
                            color: $grey-tint-6;
                        }

                        .show-more-info {
                            color: #000000;
                        }

                        &.form-group-info {
                            .label-checkbox:before {
                                top: 0.2rem;
                            }
                        }
                    }
                }
            }

            .capcha {
                &-image-wrapper {
                    float: left;
                    margin-top: 5.8rem;
                    padding-left: 0;
                    width: 50%;
                }

                &__image {
                    margin-top: 0;
                    margin-right: 2.5rem;
                    width: 60%;
                }

                &__refresh {
                    margin-top: 1rem;
                }
            }
        }
    }
}