.navigation-bar {
    padding: 3.2rem 0;
}

#menu-toggle {
    display: none;
}

.main-navigation {
    background: transparent;
    box-shadow: none;
    left: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
}

.mobile-navigation {
    display: none;
}

.user-menu {
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
    margin: 0 0 0.5rem 0;


    .palceholder {
        &-desktop {
            display: block;
            padding-left: 1rem;
        }

        &-mobile {
            display: none;
        }
    }

    li {
        padding-top: 1rem;
        padding-bottom: 1rem;

        a {
            color: $dark-blue;
            font: {
                family: $font-application;
                size: 1.4rem;
            }
            line-height: 1.6rem;
            opacity: 0.4;
            padding: 0 1rem;
            text-transform: none;
            text-decoration: underline;
            vertical-align: top;
            width: auto;
        }

        &.user-details {

            a {
                color: $dark-blue;
                font: {
                    family: $font-application;
                    size: 1.4rem;
                    weight: 400;
                }
                margin-right: 1.2rem;
            }
        }

        &.user-login,
        &.user-create-account {
            &:before {
                background-repeat: no-repeat;
                background-size: cover;
                content: "";
                display: inline-block;
                height: 1.6rem;
                //vertical-align: middle;
            }

            a {
                strong {
                    vertical-align: middle;
                }

                &:hover {
                    text-decoration: none;
                }
            }

        }

        &.user-login {
            &:before {
                background-image: url("../../../images/new_design/user-placeholder.png");
                width: 1.4rem;
            }
        }

        &.user-create-account {
            &:before {
                background-image: url("../../../images/new_design/icon-edit.png");
                width: 1.6rem;
            }
        }
    }
}

.user-menu__links {
    order: 1;
    display: flex;
}

.user-account-mobile {
    display: none;
}

.navigation {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    margin: 0;

    li {
        a {
            color: $dark-blue;
            text-transform: none;
            width: auto;
        }
    }
}

%navigation--active-link,
.navigation--active-link {
    background: {
        image: url("/images/new_design/navigation-hover-background.png");
        size: 100% 100%;
        position: center;
        repeat: no-repeat;
    }
    color: $white !important;
}





