@media only screen and (min-width: $screen-m) {
    .active-quiz {
        &__question {
            font-size: 4.9rem;
            line-height: 5.3rem;
        }

        &__description {
            margin-top: 4.5rem;
        }
    }

    .daily-question {
        padding: 5rem;
    }

    .past-quiz {
        margin-bottom: 0;
        padding: 5rem;

        &__winners-link {
            left: 5rem;
        }
    }

    .business {
        padding: 0 5rem;
    }
}