@media only screen and (min-width: $screen-m) {

    .account-container {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        section {
            box-sizing: border-box;
            width: 76%;
            padding: 0 0 0 7rem;
        }

        .title__container {
            margin-bottom: 4rem;
        }
    }

    .account-navigation-container {
        display: block;
        font-size: 1.9rem;
        width: 24%;
    }

    .account-navigation {
        background-color: $white;
        border-radius: 1.6rem;
        box-sizing: border-box;
        box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.05);
        margin-top: 4rem;
        padding: 2rem;

        li {
            a {
                display: inline-block;
                color: $dark-blue;
                font: {
                    family: $muliBlack;
                    size: 1.6rem;
                }
                padding: 0.8rem 1.2rem;
                text-decoration: none;
            }

            &.account-navigation--active-link {
                a {
                    background-image: url('/images/new_design/navigation-hover-background.png');
                    background-size: 100% 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    color: $white;
                }
            }
        }
    }

    .account-summary {
        &__extra-details {
            margin-top: 4.8rem;
        }
    }

    .points__title {
        margin-top: 3rem;
    }

    /*----------------------------------
    #Foloseste puncte
    ----------------------------------*/

    .points-page {
        .account-container {
            flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
        }

        section.vouchers,
        section.extra-chance {
            width: 100%;
        }

        .vouchers-info {
           // margin: 0 5rem;
        }
    }

    .coupon-suggest {
        margin: 8rem 5rem 0 5rem;
    }

    /*----------------------------------
        #Sondaje
    ----------------------------------*/
    .quizes {
        .quiz {
            width: 48%;
        }
    }
}