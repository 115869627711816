@media only screen and (min-width: $screen-m) {
    /* --- Text --- */

    .subtitle-big{
        font: {
            size: 4.9rem;
        }
        line-height: 5.1rem;
    }

    .title__container {
        padding-top: 1rem;
    }

    .title {

        //letter-spacing: -1rem;
    }

    .message {
        font-size: 3.9rem;
        margin: 3rem 0 3rem 0;
        letter-spacing: -0.2rem;
    }
}