@media only screen and (min-width: $screen-m) {
    .thankyou {
        &-container {
            -webkit-box-align: flex-start;
            -moz-box-align: flex-start;
            -ms-flex-align: flex-start;
            -webkit-align-items: flex-start;
            align-items: flex-start;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            justify-content: space-between;
        }

        .container-box {
            width: 45%;
        }
    }
}