@media only screen and (min-width: $screen-m) {
    .istoric-navigation {
        margin-top: 2.5rem;
        width: 94%;
    }

    .past-quiz-container {
        width: 32%;
    }

    .past-quiz__image-container {
        margin-bottom: 5rem;
    }

    .past-quiz__month {
        //padding: 5rem 0 0 0;
    }

    .past-quiz__description {
        padding: 2.8rem 0 4.5rem;
    }

    .past-quiz__winners-link {
        bottom: 5rem;
        left: 5rem;
    }

    .past-question-container {
        width: 32%;
    }

    .past-question {
        margin-bottom: 5rem;
    }

    .past-question__results {
        bottom: 5rem;
        left: 5rem;
    }

    .istoric-page {
        .past-quiz__question {
            //padding-top: 1rem;
        }
    }
}