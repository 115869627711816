@media only screen and (min-width: $screen-m) {
    
    .results {
        padding-top: 7rem;
    }

    .daily-question-archive {
        .daily {
            &.daily-sponsor {
                padding: 0 5rem;
            }
        }
    }
}