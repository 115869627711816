@media only screen and (min-width: $screen-m) {

    .page-results {
        .results-prize {
            margin-top: -5rem;
            
            &-image {
                &:before {
                    width: 19.7rem;
                    height: 19.5rem;
                }
            }
        }
    }
}