@media only screen and (min-width: $screen-m) {
    .monthly-quiz-title {
        font: {
            size: 10.1rem;
        }
        letter-spacing: -.7rem;
        opacity: .08;
    }

    .daily-question-message,
    .monthly-quiz-message {
        font-size: 3.9rem;
        margin: 3rem 0 3rem -2.5rem;
        letter-spacing: -0.2rem;
    }

    .daily-question-message {
        margin-left: 0;
    }

    .monthly-quiz-container-top {
        flex-direction: row;
        -webkit-flex-direction: row;
        -moz-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
    }

    .monthly-quiz {
        width: 60%;
    }

    .monthly-quiz-container-bottom {
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        flex-direction: row;
        -webkit-flex-direction: row;
        -webkit-justify-content: space-between;
        justify-content: space-between;
    }

    .client-information {
        width: 60%;
    }

    .quiz-sponsor {
        font-size: 7.1rem;
    }

    .client-image {
        margin-top: 5rem;
        max-width: 42rem;
        width: 35%;
    }
}