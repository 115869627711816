@media only screen and(min-width: $screen-m) {

  .press-article {
    width: 32%;
  }

  .press-article__date {
    //padding: 0 0 5rem 0;
  }

  .press-article__source {
    padding: 0 0 3rem 0;
  }

  .press-article__title {
    padding: 0 0 3rem 0;
  }

  .press-article__description {
    padding: 0 0 4.5rem 0;
  }

  .press-article__more {
    bottom: 5rem;
    left: 5rem;
  }

}